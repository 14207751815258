<template>
  <div class="dashboard-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 100%">
            <el-input v-model="searchUser" placeholder="请输入用户名称" clearable @clear="clear"
              style="wdith:150px; margin-right: 10px" />
            <el-input v-model="searchPhone" style="wdith:150px;margin-left: 20px" maxlength="11" placeholder="请输入手机号"
              clearable @clear="clear" />
            <el-button slot="append" type="primary" style="margin-left: 5px" @click="searchUserList">查询</el-button>
          </div>

        </el-col>
        <el-col :span="12" align="right">
          <el-button size="small" type="danger" @click="exportData">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button size="small" type="danger" @click="exportData">导出</el-button>
        </el-col>
      </el-row>
    </div> -->
    <div class="tab">
      <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
        ref="list" class="emp_table" v-if="list">
        <el-table-column align="center" prop="headLogo" label="头像" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image style="width: 35%" :src="'http://file.innopinenut.com/' + scope.row.headLogo" :preview-src-list="[
                'http://file.innopinenut.com/' + scope.row.headLogo,
              ]" />
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="nickName" label="微信昵称" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="phone" label="微信电话" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="shareUserName" label="用户来源" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="gender" label="性别" width="200">
          <template slot-scope="scope">
            {{ scope.row.gender | sex }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="userRole" label="用户角色" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.userRole == 2 ? '普通用户' : '无' }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="创建时间" width="200">
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="200">
          <template slot-scope="scope">
            {{ scope.row.status == 0 ? "正常" : "已拉黑" }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="status" label="是否新增" width="200">
          <template slot-scope="scope">
            <div :class="scope.row.today == true ? 'green' : ''">
              {{ scope.row.today == true ? "新增用户" : "老用户" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="setUserStatus(scope.row)">
              {{ scope.row.status == 1 ? "取消拉黑" : "拉黑" }}
            </el-button>

            <el-button type="text" size="small" @click="invitation(scope.row)">
              被邀请注册用户
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页 -->
      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.pageSize"
        @pagination="getUserList" />

    </div>
  </div>
</template>

<script>
import moment from "moment";

import {
  setUserStatus,
  getUserList
} from "../../request/http";
export default {
  data() {
    return {
      list: "", // 用户列表
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      searchUser: "",
      searchPhone: "",
    };
  },
  created() {
    this.getUserList()
  },
  methods: {
    clear() {
      this.queryParams = {}
      this.getUserList()
      this.searchUser = '',
        this.searchPhone = ''
    },
    // 查询用户
    async searchUserList() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        userName: this.searchUser,
        phone: this.searchPhone
      }
      const { data, count } = await getUserList(this.queryParams)
      this.list = data
      this.total = count
    },
    // 格式化 渲染
    fileData(row) {
      const arr = []
      row.forEach((item, index) => {
        if (index > 2) {
          return
        }
        arr.push(item.name)
      })
      return arr.join(',')
    },
    async setUserStatus(row) {
      let id = row.userId;
      let res = await setUserStatus({ userId: id })
      res.code == 0 &&
        this.$message({
          type: "success",
          message: "设置成功",
        });
      this.getUserList();
    },
    // 获取用户列表
    async getUserList() {
      const { data, count } = await getUserList(this.queryParams)
      this.list = data
      this.total = count
      console.log(data, '**');
    },
    // 导出
    exportData() {
      const headers = {
        '微信名称': 'nickName',
        '微信电话': 'phone',
        '用户来源': 'shareUserName',
        '创建时间': 'createTime',
      }
      import('@/vendor/Export2Excel').then(async excel => {
        const a = await getUserList({ page: 1, pageSize: 10000 })
        const rows = a.data
        console.log(rows)
        let newArr = rows.map((item) => {
          // console.log(item);
          return {
            nickName: item.nickName || null,
            phone: item.phone || null,
            shareUserName: item.shareUserName || null,
            createTime: moment(item.createTime).format("YYYY-MM-DD HH:mm:ss") || null,
          };
        })
        var data = this.formatJson(headers, newArr)
        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '用户列表',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item =>
        Object.keys(headers).map(key => item[headers[key]])
      )
    },

    invitation(row) {
      // console.log(row);
      this.$router.push({
        path: "invitation",
        query: {
          userId: row.userId,
        },
      });
    }
  },
};
</script>

<style>
.green {
  color: #6dce84;
}
</style>